$(document).ready(function () {

	languageSwitcher();
	productsSidebar();
	brandsCarousel();
	lightboxGallery();
	productGallery();
	videoPopup();
	formPopup();
	datepicker();
	mobileMenu();
	scrollInit();

});

function languageSwitcher() {

	if ($('.header__mainTopLanguages').length !== 0) {
		$('.header__mainTopLanguagesCurrent').on('click', function () {
			$('.header__mainTopLanguages').stop(true, true).toggleClass('visible');
		});
		$(document).on('click', function (e) {
			if (!e.target.closest('.header__mainTopLanguages') && !e.target.classList.contains('header__mainTopLanguages')) {
				$('.header__mainTopLanguages').removeClass('visible');
			}
		});
	}

}

function productsSidebar() {

	if ($('.products__sidebar').length !== 0) {
		$('.products__sidebarMenu > ul > li > a').on('click', function (e) {
			e.preventDefault();
			$(this).parent().stop(true, true).toggleClass('active');
			$(this).next().stop(true, true).slideToggle();
		});
	}

}

function brandsCarousel() {

	if ($('.brands__carousel').length !== 0) {
		new Swiper('.brands__carousel', {
			loop: true,
			speed: 500,
			slidesPerView: 6,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			breakpoints: {
				450: {
					slidesPerView: 1
				},
				680: {
					slidesPerView: 2
				},
				920: {
					slidesPerView: 3
				},
				1200: {
					slidesPerView: 4
				},
				1366: {
					slidesPerView: 5
				}
			}
		});
	}

}

function lightboxGallery() {

	if ($('.lightbox').length !== 0) {
		$('a.lightbox').simpleLightbox({
			showCounter: false,
			disableScroll: false,
			animationSpeed: 250,
			loop: false
		});
	}

}

function productGallery() {

	if ($('.product').length !== 0) {
		var productThumbs = new Swiper('.product__sliderThumbs', {
			spaceBetween: 6,
			slidesPerView: 4,
			freeMode: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
		});
		var productGallery = new Swiper('.product__sliderMain', {
			effect: 'fade',
			navigation: {
				prevEl: '.product__sliderMainNavPrev',
				nextEl: '.product__sliderMainNavNext'
			},
			thumbs: {
				swiper: productThumbs
			}
		});
	}

}

function videoPopup() {

	if ($('.youtube').length !== 0) {
		$('.youtube').magnificPopup({
			disableOn: 700,
			type: 'iframe',
			mainClass: 'mfp-fade',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false
		});
	}

}

function formPopup() {

	if ($('.formPopup').length !== 0) {
		$('a.openFormPopup').click(function (e) {
			e.preventDefault();
			$('.formPopup').stop(true, true).addClass('active');
		});
		$('.formPopup__close').click(function () {
			$('.formPopup').stop(true, true).removeClass('active');
		});
	}

}

function datepicker() {

	if ($('.datepicker').length !== 0) {
		$('.datepicker').datepicker({
			weekStart: 1,
			days: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
			daysShort: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
			daysMin: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
			months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
			monthsShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru']
		});
	}

}

function mobileMenu() {

	$('.header__trigger').click(function () {
		$(this).stop(true, true).toggleClass('visible');
		$('.header__mainBottom').stop(true, true).toggleClass('visible');
	});

}

function scrollInit() {

	if ($('.scroll-init').length !== 0) {
		$('.scroll-init').on('click', function (e) {
			e.preventDefault();
			$('html, body').animate({
				scrollTop: $($(this).attr('href')).offset().top,
			}, 600);
		});
	}

}

class PopupClose {

	constructor() {
		if (!this.vars()) return false;
		this.setupEvents();
		this.checkCookie();
	}

	vars() {
		this.ttl = 1;

		this.body = document.querySelector('body');
		this.isHome = this.body.classList.contains('page-template-home');

		if (!this.isHome) return false;

		return true;
	}

	setupEvents() {
		const self = this;
		$('.formPopup__close').click(function () {
			self.closePopup();
		});
	}

	setCookie(cname, cvalue, exdays) {
		const d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		const expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	getCookie(cname) {
		const name = cname + "=";
		const ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	checkCookie() {
		let cookie = this.getCookie("popup-closed");
		if (cookie == "") {
			this.showPopup();
		}
	}

	showPopup() {
		setTimeout(function () {
			$('.formPopup').stop(true, true).addClass('active');
		}, 5000);
	}

	closePopup() {
		$('.formPopup').stop(true, true).removeClass('active');
		this.setCookie('popup-closed', '1', this.ttl);
	}

}

new PopupClose();
